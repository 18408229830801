import L from 'leaflet'
import React from 'react'
import cx from 'classnames'
import config from 'config'
import { Marker, MapContainer, TileLayer, AttributionControl, Tooltip, Circle } from 'react-leaflet'
import { useUserLocation } from 'models/mapLayer'

import s from './VgdTransportMap.scss'


type VgdTransportMapProps = {
  className?: string
}

const markerIcon = new L.DivIcon({
  className: s.markerIcon,
  iconSize: [ 12, 12 ],
  iconAnchor: [ 6, 6 ],
  tooltipAnchor: [ 0, -6 ],
})

const VgdTransportMap: React.FC<VgdTransportMapProps> = (props) => {
  const { className } = props

  const { userLocation, isDone } = useUserLocation()

  return (
    <MapContainer
      className={cx(className, 'radius-24 overflow-hidden w-full h-full absolute pointer')}
      center={userLocation || config.cityCenter}
      zoom={14}
      scrollWheelZoom={false}
      crs={L.CRS.EPSG3395}
      editable={false}
      zoomControl={false}
      doubleClickZoom={false}
      whenCreated={(map) => {
        map.locate({ setView: true, enableHighAccuracy: true, maxZoom: 14 })
        map.dragging.disable()
      }}
      attributionControl={false}
      keyboard={false}
    >
      <TileLayer
        url="https://core-renderer-tiles.maps.yandex.net/tiles?l=map&v=21.07.28-0-b210701140430&x={x}&y={y}&z={z}&scale=2&lang=ru_RU"
        attribution={`<div style="display: flex; align-items: center;">© Яндекс
                <a style="margin-left: 4px; color: black;" href='https://yandex.ru/legal/maps_termsofuse/?lang=ru'>Условия использования</a>
                <img style="margin: 0 4px;" src="${config.storage}/common/icons/other/map/yandex-logo.svg"/></div>`}
      />
      {
        (isDone && userLocation) && (
          <>
            <Circle center={userLocation} pathOptions={{ fillColor: '#3D75E4', fillOpacity: 0.16, stroke: false }} radius={32 * 2 * 2} />
            <Circle center={userLocation} pathOptions={{ fillColor: '#3D75E4', fillOpacity: 0.16, stroke: false }} radius={48 * 2 * 2} />
            <Circle center={userLocation} pathOptions={{ fillColor: '#3D75E4', fillOpacity: 0.16, stroke: false }} radius={64 * 2 * 2} />
            <Marker
              position={userLocation}
              icon={markerIcon}
            >
              <Tooltip
                className={s.tooltip}
                permanent
                direction="top"
                opacity={1}
              >Моё местоположение</Tooltip>
            </Marker>
          </>
        )
      }
      <AttributionControl position="bottomright" prefix={false} />
    </MapContainer>
  )
}

export default VgdTransportMap