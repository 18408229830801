import { useEffect, useRef, useState } from 'react'


const useUserLocation = () => {
  const [ userLocation, setUserLocation ] = useState<[ number, number ]>()
  const [ isDone, setIsDone ] = useState<boolean>(false)
  const isUnmountedRef = useRef<boolean>(false)

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(({ coords }) => {
      if (!isUnmountedRef.current) { // if component has been already unmounted we are not setting its state to prevent memory leak
        setUserLocation([ coords.latitude, coords.longitude ])
        setIsDone(true)
      }
    }, (error) => {
      if (!isUnmountedRef.current) {
        setIsDone(true)
      }
    }, { enableHighAccuracy: true })

    return () => {
      isUnmountedRef.current = true
    }
  }, [ isUnmountedRef ])

  return {
    userLocation,
    isDone,
  }
}

export default useUserLocation